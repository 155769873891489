/* Tables
================================== */
.Rtable {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin:  0;
    padding: 0;
    position: relative;
  }
.Rtable-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;  
    padding: 0.4em 0.8em;
    overflow: hidden; 
    background: fade(slategrey,20%);
    justify-content: center;
}

/* .Rtable-cell > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; } */

/* Table column sizing
================================== */
.Rtable--2cols > .Rtable-cell  { width: 50%; }
.Rtable--3cols > .Rtable-cell  { width: 33.33%; }
.Rtable--4cols > .Rtable-cell  { width: 25%; }
.Rtable--5cols > .Rtable-cell  { width: 20%; }
.Rtable--6cols > .Rtable-cell  { width: 16.6%; }

.Rtable-special-width {
    box-sizing: border-box;
    flex-grow: 1;
    width: 10%;  
    padding: 0.4em 0.8em;
    overflow: hidden; 
    background: fade(slategrey,20%);
    justify-content: center;
}

.value-width > .Rtable-special-width {
    width: 80%;
}

table{
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px rgba(10, 56, 28, 0.5);
    width: 100%;
}

.table-title{
    border-bottom: 2px solid #468908;
    padding-bottom: 5%;
}

.table-title-item{
    font-weight: bold;
    font-size: 1.35rem;
    color: rgba(0, 0, 0, 0.428);
}

.table-item{
    font-size: 1.4rem;
    border-bottom: 0.5px solid rgb(58, 211, 122);
    margin-bottom: 10px;
    font-weight: bold;
    margin: 1% 0;
}

.view-user-holder__delete{
    cursor: pointer;
}


input[type='submit'].signup-button{
    background-color: #468908;
    color: white;
    outline: none;
    border: none;
    font-size: 0.9rem;
    padding: 0.5em;
}

.signup-time{
    font-size: 1rem;
    word-wrap: wrap;
    color: #E84641;
}

.editing-timetable{
    display: flex;
    flex-direction: row;
    margin-right: 2rem;
    justify-content: space-between;
    flex: 1;
}

.icon-editing-delete{
    margin: 1rem;
}

.icon-editing-timetable{
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    fill: red;
}