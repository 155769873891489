.form-view > label {
    padding: 0px 0px 10px;
  }
  
  #add-items__form {
    text-align: left;
    width: 100%;
    padding: 2% 5%;
  }
  
  .form-view {
    display: flex;
    flex-direction: row;
  }
  .form-view__add-video{
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: scroll;
  }
  
  
  .form-view__item-view {
    flex: 1;
    margin-top: 3em;
  }
  .form-view__item-video{
    margin: 3em;
    flex: 1;
  }
  .form-view__categories-list{
    display: flex;
    width: 20%;
    flex-direction: column;
  }
  
  .form-view__categories-list > ul{
    display: flex;
    flex-direction: column;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
  }
  .form-view__categories-list__add-video > ul{
    flex-direction: row;
    align-items: center;
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    flex-wrap: wrap
  }
  .form-view__categories-list__add-video{
    width: 80%;
  }
  .hide{
    display: none;
  }
  .form-view__categories-list > h2 {
    /* text-align: left; */
    font-size: 1rem;
    margin: 0;
    /* padding-left: 1.8em; */
    margin-bottom: 1em;
  }
  
  h2 {
    text-align: center;
  }
  
  .add-items__form-view{
    display: flex;
    flex-direction: column;
    /* align-items: baseline; */
    margin: 0 0%;
  }
  
  .add-items__form-view > label {
    margin-top: 1rem;
    /* padding-left: 10%; */
  }
  
  label span {
    display: inline-block;
    margin-right: 1rem;
    width: 7em;
    font-size: 1.2rem;
    font-weight: 700;
  }
  label span input[type='text'] {
    width: 7em;
  }

  .video__card-text span{
    width: 100%;
    float: left;
    text-align: left;
    letter-spacing: .2;
    /* padding: 0.2em; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
  .Actions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .video-icon{
    padding: 0.4em 2em;
    cursor: pointer;
  }
  
  .icon-bin, .icon-pencil, .icon-star-full{
    width: 1em;
    height: 1em;
    fill: #E84641;
    bottom: 0;
    right: 0;
  }
  .icon-file-empty{
    fill: rgba(0, 0, 0, 0.231);
  }
  .icon-pencil{
    fill: rgba(40, 68, 189, 0.678);
  }
  .icon-star-full {
    fill: yellow;
  }
  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    fill: rgba(0, 0, 0, 0.21);
  }
  .Name{
    font-family: Roboto, Arial, sans-serif;
    padding: 0.5em 0;
    font-size: 1rem;
    /* letter-spacing: .1px; */
    line-height: 1rem;
    font-weight: bold;
    position:relative;
  }
  .Name span{
    display:none;
  }
  .Name:hover span{
    position:fixed;
    overflow:hidden;
    clear: both;
    word-wrap: nowrap;
    white-space: nowrap;
    padding: 0.2em 0.5em;
    border-radius: 0.2rem;
    font-size: 0.8rem;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    width: initial;
  }
  .Date{
    font-size: 0.8rem;
    color: #1a3501c6;
    /* letter-spacing: 0.3; */
    line-height: 1rem;
    font-weight: bold;
  }
  
  .form-view__categories-list-item{
    font-size: 0.8em;
    text-align: left;
    padding: 1.2em 2em;
    font-weight: bold;
    cursor: pointer;
    text-transform: lowercase;
  }
  .form-view__categories-list-item:first-letter{
    text-transform: uppercase;
  }
  
  iframe{
    border: none;
    /* border-radius: 0.5rem; */
  }
  
  .add-items__form-view input[type=submit]{
    background-color: #468908;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px 26px;
    cursor: pointer;
    text-align: center;
    margin-top: 1.5rem;
    align-self: center;
    font-weight: 750;
  }

  .add-items__form-view input[type=reset]{
    background-color: #9e1706;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px 26px;
    cursor: pointer;
    text-align: center;
    margin-top: 1.5rem;
    align-self: center;
    font-weight: 750;
  }
  
  .add-items__form-view input[type=text], .add-items__form-view input[type=url], .add-items__form-view textarea[type=text] {
    border: 1px solid #468908bd;
    border-radius: 5px;
    padding: 10px 10px;
  }
  
  .hide{
    display: none;
  }
  .show{
    margin: auto;
  }
  .video__list{
    display: flex;
    flex-direction: column;
    width: 15rem;
    margin: 1.5%;
  }
  
  .video__card {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0, 0, 0, 0.088); */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
  
  .video__card iframe{
    width: 100%;
  }
  .video__card-text{
    width: 90%;
    padding: 0.4em 0;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    /* display: -webkit-box; */
    max-height: 3.2rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.8rem;
    max-height: 4.4rem;
    position: relative;
  }
  
  .form-view__item-video ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .parentName {
    padding: 2rem;
  }
  
  .video ul{
    display: flex;
    flex-wrap: wrap;
  }
  
  .parent-holder{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .total_view > ul{
    border: none;
  }
  .total_view{
    margin-left: auto;
    margin-right: auto;
  }
  .login-route{
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
  }
  .login-route > .item{
    color: red;
  }

  .export__button{
    background-color: #ececec; color: black; /* Green */
    border: none;
    border-radius: 10px;
    padding: 1rem 3rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .export__button:hover{
    background-color: #bce3b1; color: black; /* Green */
  }

  

  @media only screen and (max-width: 900px) {
    /* For mobile phones: */
    .video__card{
      width: 100%;
      margin-bottom: 1rem;
    }
    .video__list{
      flex-direction: column;
    }
    .form-view {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 5% 0;
    }
    
    .form-view__categories-list > ul{
      display: flex;
      flex-direction: row;
      border: 0;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      margin: 0;
      overflow-y: scroll;
    }

    .center__btn{
      align-self: center;
    }

    .form-view__categories-list h2{
      padding: 1em;
      font-size: 0.6rem;
      font-weight: bold;
    }
    .form-view__categories-list{
      flex-direction: row;
      width: 100%;
      align-items: center;
    }
    .form-view__item-view {
      margin: 0;
      padding: 0;
    }
    .form-view__categories-list > h2 {
      margin: 0;
    }
    .form-view__categories-list-item{
      font-size: 0.5em;
      text-align: left;
      padding: 0.6em 1em;
      font-weight: bold;
      cursor: pointer;
    }
    .video ul{
      margin: 0;
    }
    .video__card span{
      font-size: 0.6em;
    }
    /* .video__card-text span{
      padding: 0.2em;
    } */
    .video__card-text{
      padding: 0.4em 0.8em;
      position: relative;
    }
    .add-items__form-view{
      margin: 0 5%;
    }
    .add-items__form-view input[type=submit]{
      background-color: #468908;
      border: none;
      border-radius: 10px;
      color: white;
      padding: 10px 26px;
      cursor: pointer;
      margin-top: 1.5rem;
    }
  
    .add-items__form-view input[type=reset]{
      background-color: #9e1706;
      border: none;
      border-radius: 10px;
      color: white;
      padding: 10px 26px;
      cursor: pointer;
      margin-top: 1.5rem;
      margin-left: 10%;
    }
    
    label span {
      width: auto;
    }
    .view-holder__list-item span {
      font-size: 1rem;
    }
    .view-holder__list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      width: 80%;
    }
    .form-view__item-video ul{
      flex-direction: column;
    }
    .video__list{
      width: 90%;
      margin: auto;
    }
    iframe{
      border: none;
      /* border-radius: 0.5rem; */
      width: 100%;
    }
  }
