.SystemItem {
    flex: 1;
    position: relative;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;

    
    &:hover {
        border-color: transparent;
        background-color: rgba(92, 206, 4, .15)
    }

    &--active {
        border-color: transparent;
        border-top: 3px solid rgb(92, 206, 4);
    }
}

