.Footer {
    padding: 2rem 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    background-color: #3f7a07;
}

.list-style-none {
    list-style: none;
}

.Footer__list {
    color: #e2e2e2;
    line-height: 1.5;
}

.Footer__list:not(:first-child) {
    text-align: right;
}

.Copyright {
    padding: 2rem 3rem;
    background-color: #4c9607;
    border-top: 2px solid white;
    border-bottom: 2px solid #e84641;
    color: #e2e2e2;
}

.text-orange {
    color: #e84641;
}

.Footer__list > li {
    cursor: pointer;
}