@import '../../Stylesheets/abstract';

.CoursesView {
    position: relative;
    padding: 2rem;
    display: grid;
    grid-gap: 3rem;
    grid-auto-rows: minmax(min-content, max-content);
    max-height: 600px;
    overflow: scroll;
    text-overflow: ellipsis;
    // overflow-x: hidden;
    // flex: 1;

    &--list {
        display: flex;
        flex-direction: column;
    }
    &--split {
        grid-template-columns: repeat(3, minmax(22rem, 30rem));

        @include respond(tab-port) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include respond(phone) {
            grid-template-columns: 1fr;
        }
    }
    &--no-split {
        grid-template-columns: 1fr;
    }
    &__form-view{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    &__form-view > label {
        margin-top: 0.3rem;
        display: flex;
        flex-direction: column;
      }
    &__form-view input[type=submit], .add-items__form-view input[type=submit], .add-items__form-view input[type=reset] {
        background-color: #468908;
        border: none;
        color: white;
        padding: 10px 26px;
        text-decoration: none;
        cursor: pointer;
        text-align: center;
        /* width: 20%; */
        margin-top: 1rem;
        align-self: center;
      }
    &__form-view input[type=text], &__form-view input[type=url], &__form-view textarea[type=text] {
        border: 2px solid #468908bd;
        border-radius: 4px;
        padding: 8px 5px;
        font-size: 1em;
      }
    
}

.CoursesView > p {
    font-size: 2rem;
    font-weight: bold;
}

.CourseLink {
    display: inline-block;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0rem 2rem;
    transition: all .2s;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -2rem;

    &:link,
    &:visited {
        color: inherit;
    }

    &:hover {
        color: #4c9607;
    }
    &:active {
        color: #3f7a07;
    }
}

.toggle {
    --width: 40px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);

    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}
.toggle__input {
    display: none;
}

.toggle__fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: #dddddd;
    transition: background 0.2s;
}
.toggle__fill::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
}
.toggle__input:checked ~ .toggle__fill {
    background: #009578;
}

.toggle__input:checked ~ .toggle__fill::after {
    transform: translateX(var(--height));
}

