.SideMenuItem,
.SideMenuDropdown {
    padding: 1.7rem 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    font-size: 1.5rem;
    font-weight: 700;
    background-color: rgba(92,206,4,.09);
    border-bottom: 1px solid white;
    text-transform: capitalize;
}

.SideMenuDropdown span,
.SideMenuItem span {
    text-transform: capitalize;
    position: relative;
}

.SideMenuDropdown::after,
.SideMenuItem::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: rgba(92,206,4,.3);
    transition: all .3s ease-out;
    z-index: 100;
}

.SideMenuDropdown:hover::after,
.SideMenuItem:hover::after {
    width: 100%;
}

.SideMenuItem--active {
    border-color: transparent;
    background-color: rgba(92,206,4,.3)
}

.SideMenuDropdown--sub {
    max-height: 0;
    opacity: 0;
    transition: all .4s ease-out;
}

.SideMenuDropdown--sub .SideMenuItem {
    padding-left: 7rem;
}

.showMenuDropDown {
    max-height: 100%;
    opacity: 1;
    visibility: visible;
}

.hideMenuDropDown {
    max-height: 0;
    opacity: 0;
    display: none;
    visibility: collapse;
}
