.auth-items {
    margin: 20% auto;
    width: 30%;
    padding-bottom: 10%;
}

.auth-items input[type='text'], .auth-items input[type='password'], .auth-items input[type='email'], .auth-items select{
    box-sizing: border-box;
    border: none;
    background-color: #46890828;
    padding: 1em 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    width: 100%;
}

input[type='submit'].auth-button{
    background-color: #468908;
    color: white;
    outline: none;
    border: none;
    border-radius:3px;
    font-size: 1.5rem;
    font-weight:bold;
    padding: 1em;
    margin-top: 5%;
    cursor:pointer
}

input[type='checkbox']{
    vertical-align: text-bottom;
    margin-right: 0.5rem;
}

.auth-items__form-view{
    margin-top: 8%;
}

.auth-items__form-view {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .auth-items {
        margin: 50% auto;
        width: 90%;
        padding-bottom: 10%;
        font-size: 0.8rem;
    }
}