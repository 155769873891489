body {
  min-height: 100%;
  color: #191919;
  background-color: #f5f5f5;
  letter-spacing: .1px;
  /* font: 400 14px/1.6 "PT Serif", Verdana, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("http://www.minesec.gov.cm/wp-content/uploads/2017/11/Background-PC-2-MINESEC.jpg"); */
  /*background-position: center top;*/
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
  /*background-attachment: fixed;*/
  font-family: 'Roboto', sans-serif;
}

.App {
  font-family: 'Roboto', sans-serif;
}

.App-intro {
  font-size: large;
}

h5 {
  display: block;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  padding: 14px 21px;
  font-weight: bold;
  margin: 0;
  margin-left: 2em;
}

li {
  /*text-align: center;*/
  margin: 5px 0px;
}

ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

form {
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  margin: auto;
}

/*@media only screen and (max-width: 768px) {*/
/*  !* For mobile phones: *!*/
/*  .App-header{*/
/*    padding: 0.8rem 2rem 0.8rem 0.8rem;*/
/*  }*/
/*  .App {*/
/*    width: 100%; */
/*  }*/
/*}*/

/*@media only screen and (max-width: 1300px) and (min-width:  768px) {*/
/*  !* For mobile phones: *!*/
/*  .App {*/
/*    width: 90%; */
/*  }*/
/*}*/