.view-holder {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 2rem;
}

.view-holder__list-item {
    width: 60%;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.view-holder__delete{
    width: 10%;
    cursor: pointer;
    padding: 2%;
}

.edit-items__form-view {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.view-holder__list-item form{
    margin: 0;
}

.edit-items__form-view input[type=submit]{
    background-color: #203f03;
    border: none;
    color: white;
    padding: 6px 18px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    margin: 0;
}

.edit-items__form-view ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: black;
    font-weight: 500;
  }
.edit-items__form-view ::-moz-placeholder { /* Firefox 19+ */
color: black;
font-weight: 500;
}
.edit-items__form-view :-ms-input-placeholder { /* IE 10+ */
color: black;
font-weight: 500;
}
.edit-items__form-view  :-moz-placeholder { /* Firefox 18- */
color: black;
font-weight: 500;
}


.view-holder .view-holder__view-items{
    flex: 1;
}

input[type='text'], input[type='password'], input[type='email']{
    box-sizing: border-box;
    border: none;
    background-color: #46890828;
    padding: 1em 3em;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    width: 100%;
}

select{
    padding: 1.5em 3em;
    font-size: 1.2rem;
    text-align: center;
}