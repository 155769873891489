.view-user-holder{
    padding: 2rem;
}

li.view-user-holder__list-item{
    display: flex;
    flex-direction: row;
}
form.edit-user__form-view{
    display: flex;
    flex-direction: row;
}
.view-user-holder__delete{
    width: 3%;
    height: 3%;
    margin-top: auto;
    margin-bottom: auto;
}

/* Tables================================== */
.Rtable {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }

.Rtable-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;  
    padding: 0.4em 0.8em;
    overflow: hidden; 
    list-style: none;
    background: fade(slategrey,20%);
    text-align: left;
}

/* .Rtable-cell > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; } */

/* Table column sizing
================================== */
.Rtable--2cols > .Rtable-cell  { width: 50%; }
.Rtable--3cols > .Rtable-cell  { width: 33.33%; }
.Rtable--4cols > .Rtable-cell  { width: 25%; }
.Rtable--5cols > .Rtable-cell  { width: 20%; }
.Rtable--6cols > .Rtable-cell  { width: 16.67%; }

@media only screen and (max-width: 768px) {

    li.view-user-holder__list-item{
        display: flex;
        flex-direction: row;
        padding-bottom: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
    label{
        width: 100%;
        margin-right: 2rem;
    }
    form.edit-user__form-view{
        display: flex;
        flex-direction: column;
    }
    .view-user-holder__delete{
        width: 1.8rem;
        height: 1.8rem;
    }
    input[type=reset], input[type=submit]{
        font-size: 1.3rem;
        flex: 1;
    }
    input[type=submit] {
        /* padding: 0.4em 0.8em !important; */
    }
}