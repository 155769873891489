@import '../../Stylesheets/abstract';

.MainView {
  background: linear-gradient(to bottom, rgb(199, 252, 100), rgb(63, 122, 7));
  min-height: 100vh;
}

.MainView--main {
  grid-row: 0/1;
  padding-top: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;

  @include respond(tab-port) {
    margin: 3rem;
    width: 100%;
  }
  @include respond(phone){
    margin: 0;
    width: 100%;
    padding-top: 0;
  }
  @include respond(tab-land-small){
    margin: 0;
    width: 100%;
  }
}


.stock-ticker {
  font-size: 15px;
  padding-block: 8px;
  border-block: 1px solid;
  overflow: hidden;

  --gap: 100px;
  display: flex;
  gap: var(--gap);
}

.stock-ticker ul {
  list-style: none;
  flex-shrink: 0;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap);

  animation: scroll 25s linear infinite;
}

.stock-ticker:hover ul {
  animation-play-state: paused;
}

@keyframes scroll {
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}


/* begining of the text scroller */

.scroller--size {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.stock-ticker .company{
  font-weight: bold;
}

.stock-ticker .minus::before {
  content: "↓";
}

.stock-ticker .minus .change,
.stock-ticker .minus::before {
  color: #089981;
}


.color-red{
  color: red;
}


// .container {
//   display: grid;
//   grid-template-rows: 1fr;
//   grid-template-columns: [full-start] 1fr [center-start] repeat(8, [col-start] minmax(min-content, 17rem) [col-end]) [center-end] 1fr [full-end];
// }
