.SystemItems {
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.Min-goal p,
.Min-info P {
    flex: 5;
    text-align: center;
}

.Min-goal {
    background-color: #3f7a07;
    color: white;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 2rem;
    text-transform: capitalize;
    text-align: center;
}