@import '../../Stylesheets/abstract';

.Header {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.Flag {
    display: flex;
    align-items: stretch;
}

.Flag--red {
    flex: 4;
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    background-color: #e84641;
    color: #ecdf00;
    text-align: center;
    padding: 2rem 0;
    font-size: 1.6rem;

    @include respond(tab-port) {
        flex: 6;
    }
}

.Flag--green {
    flex: 3;
    background-color: #3f7a07;
    padding: 1rem 0;
    @include respond(tab-port) {
        flex: 1.5;
    }
    display: flex;
    align-items: center;
}

.Flag--yellow {
    flex: 3;
    background-color: #ecdf00;
    padding: 1rem 0;
    margin-left: -10rem;
    @include respond(tab-port) {
        flex: 2.5;
    }
    display: flex;
    align-items: center;
}

.flex-split {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Min-info {
    padding: 3rem 4rem;
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 600;
}

.Flag--green P,
.Flag--yellow P {
    flex: 5;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: capitalize;
    text-align: center;
}

.Min-goal {
    background-color: #3f7a07;
    padding: 2rem;
}
