@import '../../Stylesheets/abstract';

.Content {
    flex: 1;
    background-color: white;
    @include respond(tab-land) {
        padding: 0 3rem;
    }
    @include respond(phone) {
        padding: 0 1rem;
    }
}

.MainView__content {
    display: flex;
    position: relative;
    @include respond(tab-port) {
        padding-top: 5rem;
    }
}

.MainView__main-view {
    flex: 7.5;
    background-color: rgba(226, 226, 226, .5);
    min-height: 40vh;
    -webkit-transition: width .5s ease-in-out;
    -moz-transition: width .5s ease-in-out;
    -o-transition: width .5s ease-in-out;
    transition: width .5s ease-in-out;
    @include respond(phone) {
        width: 10%;
        flex: none;
    }
    @include respond(tab-port) {
        width: 10%;
        flex: none;
    }
}
.Main__show{
    @include respond(tab-port) {
        width: 100%;
        flex: none;
    }
}
.Main__hide{
    @include respond(phone) {
        width: 10%;
        flex: none;
    }
    @include respond(tab-port) {
        width: 40%;
        flex: none;
    }
}

.Filters {
    padding: 2rem 0;
    background-color: white;
    overflow-x: hidden;
}

.Categories {
    display: flex;
    flex-wrap: wrap;
    @include respond(tab-port) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.Categories .FilterList {
    margin-right: 4rem;
    @include respond(tab-port) {
        margin-right: 0;
    }
}

.breadcrum {
    position: absolute;
    top: -1.5rem;
    left: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    border: 2px solid rgba(46, 139, 86, 0.473);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover{
        color: rgb(46, 139, 86);
    }
    &--icon-arrow-left2{
        width: 1.8em;
        height: 1.8em;
        cursor: pointer;
    }
}
.center{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}
.marquee {
    color: red;
    flex: 1;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
    padding-right: 2rem;
  }
  
  .marquee span {
    display: inline-block;
    width: max-content;
    font-size: 1.5rem;
  
    padding-left: 100%;
    /* show the marquee just outside the paragraph */
    will-change: transform;
    animation: marquee 15s linear infinite;
  }
  
  .marquee span:hover {
    animation-play-state: paused
  }
  
  
  @keyframes marquee {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }
  
  
  /* Respect user preferences about animations */
  
  @media (prefers-reduced-motion: reduce) {
    .marquee span {
      animation-iteration-count: 1;
      animation-duration: 0.01; 
      /* instead of animation: none, so an animationend event is 
       * still available, if previously attached.
       */
      width: auto;
      padding-left: 0;
    }
  }