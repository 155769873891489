@import '../../Stylesheets/abstract';

.SideMenuToggle {
    cursor: pointer;
    position: absolute;
    top: 3rem;
    left: 0%;
    width: 4rem;
    height: 4rem;
    display: none;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    -webkit-transition: all .6s ease-in-out;
    -moz-transition: all .6s ease-in-out;
    -o-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;

    @include respond(tab-port) {
        display: flex;
        visibility: visible;
    }
}
.toRight{
    left: 80%;
}
.SideMenu {
    flex: 2.5;
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
    margin-bottom: 3rem;
    max-height: 600px;
    opacity: 1;
    -webkit-transition: width .5s ease-in-out;
    -moz-transition: width .5s ease-in-out;
    -o-transition: width .5s ease-in-out;
    transition: width .5s ease-in-out;
    overflow-y: scroll;
    text-transform: capitalize;
    margin-top: 3rem;
    @include respond(tab-port) {
        width: 0rem;
        padding: 0rem;
    }

}

.showMenu {
    padding-right: 2rem;
    margin-bottom: 3rem;
    @include respond(phone) {
        padding-right: 2rem;
        margin-bottom: 3rem;
        width: 90%;
        display: block;
        flex: none;
    }
    @include respond(tab-port) {
        width: 90%;
        display: block;
        flex: none;
        padding-right: 2rem;
        margin-bottom: 3rem;
    }
}

.burger {
    display: inline-block;
    cursor: pointer;
  }
.bar1, .bar2, .bar3 {
width: 25px;
height: 2px;
background-color: #333;
margin: 6px 0;
transition: 0.4s;
}

.change .bar1 {
-webkit-transform: rotate(-45deg) translate(-6px, 3px);
transform: rotate(-45deg) translate(-6px, 3px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
-webkit-transform: rotate(45deg) translate(-8px, -8px);
transform: rotate(45deg) translate(-8px, -8px);
}
