@import '../Stylesheets/abstract';

.date-control {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin: 2rem;
    @include respond(tab-port) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    & > span {
        font-size: 1.5rem;
        font-weight: bold;
    }
}