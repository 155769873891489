.FilterList {
    display: flex;
    max-width: 90%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    &--scroll-no-show {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    @media only screen and (min-width:1025px) {
        max-width: 900px;
    }
    @media only screen and (min-width:801px) and (max-width:1200px)  {
        max-width: 600px;
    }
    @media only screen and (max-width:801px) {
        max-width: 900px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}