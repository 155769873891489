.CourseView {
    background-color: white;
    height: 25rem;
    transition: all .2s;
    position: relative;
    overflow: hidden;
}

.CourseViewActive {
    grid-row: 1/2;
    grid-column: 1/-1;
    height: 40rem;
    /* position: absolute; */
    z-index: 1;
    width: 100%;
}

.CloseCourseButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 5rem;
    height: 5rem;
    /*border-radius: 50%;*/
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.video__card-text {
    height: 100%;
}

#myIframe{
    position: relative;
    height: 75%;
    width: 100%; 
}

.CourseViewActive > #myIframe{
    height: 85%;
}

#Name{
    font-family: Roboto, Arial, sans-serif;
    padding: 0.5em 0;
    padding-left: 1rem;
    font-size: 1.5rem;
    line-height: 1rem;
    font-weight: bold;
    position:relative;
  }
#Name span{
    display:none;
  }
#Name:hover span{
    position:fixed;
    overflow:hidden;
    clear: both;
    word-wrap: nowrap;
    white-space: nowrap;
    padding: 0.2em 0.5em;
    border-radius: 0.2rem;
    font-size: 1.5rem;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    width: initial;
  }
#Date{
    font-size: 1.3rem;
    color: #1a3501c6;
    line-height: 1rem;
    font-weight: bold;
    padding-left: 1rem;
  }

.Delete{
    position: absolute;
    top: 0;
    right: 0;
}
.edit{
    position: absolute;
    top: 0;
    left: 0;
}

.video-icon {
    background-color: white;
    border-radius: 100rem;
    text-align: center;
}
.close{
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    background-color: red;
}