.TabItem {
    flex: 1;
    border: 2px solid rgba(92, 206, 4, .3);
    position: relative;
    cursor: pointer;
    color: #3f7a07;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;
    box-sizing: border-box;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: .4rem;
        width: 0;
        background-color: rgba(92,206,4, .5);
        border-radius: 5px;
        transition: all .2s;
    }
    &:hover, &:active {
        border-color: transparent;
        background-color: rgba(92, 206, 4, .15)
    }
    &:hover::after, &:active::after {
        width: 100%
    }
    &:not(:last-child) {
        border-right-color: transparent;
    }

    &--active {
        border-color: transparent;
        background-color: rgba(92, 206, 4, .09)
    }
    &--active::after {
        width: 100%;
    }
    &__title{
        display: flex;
        flex-direction: column;
        align-items: center;
        &__sub {
            color: rgba(0, 0, 0, 0.6);
            font-size: 1rem;
        }
    }

    &__sub {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        transition: all .3s ease-out;
        transform-origin: top;
        z-index: 50;
        max-height: 0;
        opacity: 0;
        visibility: hidden;

        &--item {
            padding: 2rem;
            color: #3f7a07;
            cursor: pointer;
            background-color: white;
            transition: all .2s;
            position: relative;

            & > span {
                z-index: 60;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 2px;
                background-color: white;
                transition: all .2s;
                z-index: 55;
            }

            &:hover::after {
                background-color: rgba(92, 206, 4, .15);
                width: 100%
            }
            &:active::after {
                width: 100%;
                background-color: rgba(92, 206, 4, .3);
            }
        }
    }
    &:hover &__sub {
        max-height: 100%;
        opacity: 1;
        visibility: visible;
    }
}

