.FilterModal {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 50rem;
    color: #3f7a07;
    background-color: rgba(92, 206, 4, .07);
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: all .2s ease-out;

    margin-right: .6rem;
    margin-bottom: 2rem;
    text-transform: capitalize;
    font-size: 1.4rem;
    white-space: nowrap;
}

.FilterModal:hover {
    background-color: rgba(92, 206, 4, .2);
    box-shadow: 0 1px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3;
    border-bottom-color: transparent;
}

.FilterModal--active,
.FilterModal:active {
    border-bottom-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    background-color: rgba(92, 206, 4, .4);
}